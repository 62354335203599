import { createApp } from 'vue'
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.dark.css';
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

const shareUrl="https://share.swqyun.com/";
const staticUrl="https://static.swqyun.com/";

const app = createApp(App)
app.provide('global',{
    shareUrl,
    staticUrl,
})
// global.config.globalProperties.$Cesium = window.Cesium // 挂载对象到全局
app.use(createPinia()).use(router).use(Antd).mount('#app')
