import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import layout from "../layout/index.vue"
// 设置一个数组 存入到strong  于获取到打列表同步
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '/',
    component: () => import('@/layout/index.vue'),
    redirect: { path: '/home' },// 重定向到
  },
  {
    path: '/home',
    name: 'layout',
    component: () => import('@/layout/index.vue'),
    redirect: { path: '/home/manage' },// 重定向到
    children: [
      {
        path: '/home/manage',
        name: 'manage',
        component: () => import('@/views/home/manage/index.vue'),
        meta: { title: '项目管理', icon: '', parent: { name: 'manage' } }
      },{
        path: '/home/userList',
        name: 'userList',
        component: () => import('@/views/home/userList/index.vue'),
        meta: { title: '用户管理', icon: '', parent: { name: 'user' } }
      },
      {
        path: '/home/userInfo/:id',
        name: 'userInfo',
        component: () => import('@/views/home/userList/components/userInfo.vue'),
        meta: { title: '用户管理', icon: '', parent: { name: 'userInfo' } }
      }, {
        path: '/home/consumptionList',
        name: 'consumptionList',
        component: () => import('@/views/home/consumptionList/index.vue'),
        meta: { title: '消费管理', icon: '', parent: { name: 'consumptionList' } }
      }, {
        path: '/home/share',
        name: 'share',
        component: () => import('@/views/home/share/index.vue'),
        meta: { title: '分享管理', icon: '', parent: { name: 'share' } }
      },  {
        path: '/home/LeavingAMessage',
        name: 'LeavingAMessage',
        component: () => import('@/views/home/LeavingAMessage/index.vue'),
        meta: { title: '留言管理', icon: '', parent: { name: 'LeavingAMessage' } }
      },
      {
        path: '/home/systemLog',
        name: 'systemLog',
        component: () => import('@/views/home/systemLog/index.vue'),
        meta: { title: '系统日志', icon: '', parent: { name: 'systemLog' } }
      },
      {
        path: '/home/systemAbout',
        name: 'systemAbout',
        component: () => import('@/views/home/systemAbout/index.vue'),
        meta: { title: '关于系统', icon: '', parent: { name: 'systemAbout' } }
      },
      {
        path: '/home/statistics',
        name: 'statistics',
        component: () => import('@/views/home/statistics/index.vue'),
        meta: { title: '数据统计', icon: '', parent: { name: 'statistics' } }
      },
      {
        path: '/home/marketing',
        name: 'marketing',
        component: () => import('@/views/home/marketing/index.vue'),
        meta: { title: '营销内容', icon: '', parent: { name: 'marketing' } },
      },
      {
        path: '/home/mainExamap',
        name: 'mainExamap',
        component: () => import('@/views/home/mainExamap/index.vue'),
        meta: { title: '官网案例页', icon: '', parent: { name: 'mainExamap' } },
      },
      {
        path: '/home/storyManage',
        name: 'storyManage',
        component: () => import('@/views/home/storyManage/index.vue'),
        meta: { title: '故事分享管理', icon: '', parent: { name: 'storyManage' } },
      },
      {
        path: '/home/storyBanner',
        name: 'storyBanner',
        component: () => import('@/views/home/storyBanner/index.vue'),
        meta: { title: '官网案例页', icon: '', parent: { name: 'storyBanner' } },
      },
    ]
  },



  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/index.vue'),
    meta: { title: '后台登陆', icon: '', parent: { name: 'user' } }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 路由拦截
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}-四维轻云`
  if (to.path == '/login') {
    // 登陆之后才能获取
    localStorage.removeItem('AdminLoginKey')
    next();
  } else {
    // 获取 token
    const token = localStorage.getItem('AdminLoginKey')
    // token 不存在
    if (token === null || token === '') {
      // message.error('您还没有登录，请先登录');
      next('/login');
    } else {
      next();
    }
  }
})

export default router
